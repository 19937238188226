import { createRoot } from 'react-dom/client'
import './App.css'
import { App } from './App.js'
// import { Logo } from '@pmndrs/branding'

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      <p style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
        bent metal cannot be straight
        <br />
        <br />
        </p>
        <p style={{ position: 'absolute', bottom: 30, left: 90, fontSize: '9px' }}>Not affiliated with Pepsi Co.</p>
      
      <br />
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>actually lgbt —</div>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>jacob's garden</div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <App />
    <Overlay />
    {/* <Logo style={{ position: 'absolute', bottom: 40, left: 40, width: 30 }} /> */}
  </>
)
